import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import illu1 from '../../images/analytics-1.svg';
import productScreenshot from '../../images/product-screenshot.png';
import PageLayout from '../../layouts/Page';
import ThankYouSmallLogo from '../../images/small_logo.svg';

const features = [
  {
    name: 'Respect de la vie privée des internautes',
    description: "Aucune donnée personnelle n'est collectée sans consentement.",
  },
  {
    name: 'Cookieless par défaut',
    description: 'Pas de consentement = pas de cookie ni autres traceurs.',
  },
  {
    name: 'Gouvernance des données',
    description: "Vous êtes l'unique propriétaire des données.",
  },
  {
    name: 'Transparence',
    description:
      'Aucun algorithme black-box ni magie, on vous explique en détail comment tout fonctionne.',
  },
  {
    name: 'Simplicité',
    description: 'Uniquement les statistiques dont vous avez besoin, pas de superflu.',
  },
  {
    name: 'Temps réel',
    description: 'Suivez les performances de vos campagnes marketing en temps réel.',
  },
  {
    name: 'Rapidité',
    description:
      'Développé sur un socle technique moderne, TY Marketing charge vos données en quelques millisecondes.',
  },
  {
    name: 'Partager vos dashboards',
    description:
      'Choisissez précisément ce que vous souhaitez partager à vos agences, régies et autres prestataires.',
  },
  {
    name: 'Hébergement',
    description: 'Les données sont hébergées exclusivement en Europe.',
  },
  {
    name: 'Indépendant',
    description: 'Made in France et financé depuis le début uniquement par nos clients.',
  },
];

const Page = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>Analysez vos campagnes marketing tout en respectant le RGPD</title>
        <meta
          name="description"
          content="Développé en réponse aux nouvelles obligations de la directive ePrivacy au 1er avril 2021, TY Marketing propose une nouvelle approche."
        />
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="font-title text-3xl font-extrabold tracking-tight text-green-brand">
              <div className="relative flex flex-column justify-center items-center">
                <img
                  className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                  src={ThankYouSmallLogo}
                  alt="Thank-You Analytics Logo"
                />
                <span>Marketing</span>
              </div>
            </h2>
            <p className="mt-4 max-w-6xl mx-auto font-title font-extrabold text-gray-900 text-2xl sm:tracking-tight lg:text-5xl">
              Analysez la performance de vos campagnes en respectant l'ePrivacy + RGPD et la vie
              privée des internautes.
            </p>
            <p className="max-w-4xl mt-5 mx-auto text-xl text-gray-500">
              Développé en réponse aux nouvelles obligations de l'ePrivacy au 1er avril 2021, TY
              Marketing propose une nouvelle approche permettant d'analyser la performance des
              campagnes d'acquisition tout en respectant la vie privée de votre audience.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white sm:-mb-96">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <img className="" src={productScreenshot} alt="Analytics" />
        </div>
      </div>
      <div className="bg-gray-50 sm:pt-96">
        <div className="max-w-7xl mx-auto pt-8 pb-16 px-4 sm:px-6 lg:pb-24 lg:pt-8 lg:px-8">
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-2 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-brand" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl text-center flex flex-col items-center mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
          <h2 className="text-xl font-title font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            <span className="block">
              Fatigué d'essayer de retrouver des statistiques perdues depuis le 1er avril 2021 ?
            </span>
            <span className="block text-green-brand">
              N'essayez plus et changez d'approche avec TY Marketing.
            </span>
          </h2>
          <div className="mt-8 flex">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/contact"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-brand hover:bg-green-brandHover"
              >
                Demande de démo
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-gray-50 pt-16 pb-32 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="flex flex-col justify-center px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
              <p className="text-4xl font-title text-gray-900">
                Ne subissez plus l'ePrivacy, comprenez ses enjeux et son périmètre. Adaptez-vous dès
                maintenant et, ensemble, réinventons la mesure de vos campagnes d'acquisition.
              </p>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 sm:pl-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img className="w-full lg:h-full lg:w-auto" src={illu1} alt="Analytics" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto text-center flex flex-col items-center py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
          <h2 className="text-xl font-title font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            <span className="block">
              Difficile d'analyser la performance de vos campagnes avec 26%* de données en moins ?
            </span>
            <span className="block text-green-brand">
              Découvrez une nouvelle méthodologie permettant d'analyser 100% des données.
            </span>
          </h2>
          <div className="mt-8 flex">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/contact"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-brand hover:bg-green-brandHover"
              >
                Demande de démo
              </Link>
            </div>
          </div>
          <p className="self-end mt-8 text-xs text-gray-500">
            * basé sur le taux de consentement moyen constaté en décembre 2021 pour les clients
            Thank-You.
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export default Page;
